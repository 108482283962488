'use client' // Error components must be Client Components
import { useEffect } from 'react'
// import Link from 'next/link'

export default function Error({ error, reset }: {
  error: Error & { digest?: string }
  reset: () => void
}) {

  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error)
  }, [error])

  return (
    <div className='global-err'>
      <hgroup>
        <h1>Ha ocurrido un error inesperado</h1>
        <p>Puedes <a href={`https://${process.env.NEXT_PUBLIC_PROJECT_HOSTNAME}`}>volver al inicio</a> o puedes <button
          onClick={
            // Attempt to recover by trying to re-render the segment
            () => reset()
          }
        >reintentar la última acción</button>.</p>
      </hgroup>
    </div>
  )
}
